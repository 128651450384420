import DoneIcon from '@mui/icons-material/Done'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useState } from 'react'
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp'
import { Spinner } from 'react-bootstrap'
import CreatableSelect from 'react-select/creatable'
import { sortArrayMomentBased } from 'src/clinic/components/ClinicManageBookingsPage/ClinicManageBookingsHelper'
import {
  CustomHTMLSelect,
  CustomInput,
  PictureUploadButton,
} from 'src/consumer/components/helperComponents'
import { INVESTIGATION_TYPE_PANEL, INVESTIGATION_TYPE_TEST } from 'src/consumer/constants'
import { getImageLink, isCommonResponseSuccessful } from 'src/mentor/helpers/utilityFunctions'
import { getMPDataWithoutParams, postMPDataWithParams } from 'src/mentor/services'
import { v4 as uuidv4 } from 'uuid'
import { InvestigationsSelectMenu } from '../../PrescriptionMakingPage/PrescriptionMakingPageComponents'
import { AddOrViewInvsComponentContainer } from './investigationStyles'
import { toast } from 'react-toastify'
import { Dialog } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

export default function AddOrViewInvestigationsForSelectedPerson(props) {
  const { patientId, mentorId, clinicBrandId, name } = props

  const [initialDataStripsLoading, setInitialDataStripsLoading] = useState(false)
  const [visibleInvestigationStrips, setVisibleInvestigationStrips] = useState([])

  const [existingInvestigations, setExistingInvestigations] = useState([])
  const [existingInvestigationScans, setExistingInvestigationScans] = useState([])

  const [reportReferenceId, setReportReferenceId] = useState('')
  const [reportDate, setReportDate] = useState(dayjs())

  async function setupDataVisibleInvestigations() {
    setInitialDataStripsLoading(true)
    const response = await getMPDataWithoutParams(
      `/api/secure/doctor/prescription/patient/list?patientId=${patientId}`,
    )
    let investigationIdsSet = new Set()
    // setting recommendations from all last prescriptions data:
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      const prescriptionDataList = response?.data?.data?.prescriptionDataList || []
      sortArrayMomentBased(prescriptionDataList, 'modifiedTime', false)
      const resultArray = prescriptionDataList?.map((pastPrecriptionsDataOnly) => ({
        ...(response?.data?.data?.prescriptionTemplateList?.[0] || {}),
        prescriptionData: pastPrecriptionsDataOnly,
      }))
      if (Array.isArray(resultArray) && resultArray.length > 0) {
        resultArray?.forEach((resultArrayItem) => {
          if (Array.isArray(resultArrayItem?.prescriptionData?.investigations)) {
            resultArrayItem?.prescriptionData?.investigations?.forEach((item) => {
              if (item?.id) {
                investigationIdsSet.add(item?.id)
              }
            })
          }
        })
      }
    }
    // setting actual saved values
    const responseTwo = await getMPDataWithoutParams(
      `/api/secure/doctor/investigation-and-report-scan/list?patientId=${patientId}&clinicBrandId=${clinicBrandId}&doctorMentorId=${mentorId}`,
    )
    if (responseTwo?.status === 200 && isCommonResponseSuccessful(responseTwo?.data?.code)) {
      setExistingInvestigationScans(
        responseTwo?.data?.data?.patientInvestigationReportScanList || [],
      )
      if (Array.isArray(responseTwo?.data?.data?.patientInvestigationList)) {
        responseTwo?.data?.data?.patientInvestigationList?.forEach((item) => {
          if (item?.investigationId) {
            investigationIdsSet.add(item?.investigationId)
          }
        })
        setExistingInvestigations(responseTwo?.data?.data?.patientInvestigationList || [])
      }
    }
    let investigationIdsArray = [...investigationIdsSet]
    // get and set strips of these investigations
    const responseThree = await getMPDataWithoutParams(
      `/api/secure/doctor/investigation/list?ids=${investigationIdsArray.join(
        ',',
      )}&page=1&pageSize=10000`,
    )
    if (responseThree?.status === 200 && Array.isArray(responseThree?.data)) {
      const panelInvestigations = responseThree?.data?.filter(
        (item) => item?.investigationType === INVESTIGATION_TYPE_PANEL,
      )
      const combinedTestIdsFromPanels = panelInvestigations.reduce((accumulator, currentItem) => {
        if (Array.isArray(currentItem?.testIds)) {
          return accumulator.concat(currentItem?.testIds)
        }
        return accumulator
      }, [])
      const finalFilteredData = []
      if (Array.isArray(responseThree?.data)) {
        responseThree?.data?.forEach((item) => {
          if (!combinedTestIdsFromPanels?.includes(item?.id)) {
            finalFilteredData.push(item)
          }
        })
      }
      setVisibleInvestigationStrips(finalFilteredData)
    }
    setInitialDataStripsLoading(false)
  }

  async function updateExistingDataValues() {
    const response = await getMPDataWithoutParams(
      `/api/secure/doctor/investigation-and-report-scan/list?patientId=${patientId}&clinicBrandId=${clinicBrandId}&doctorMentorId=${mentorId}`,
    )
    console.log(response)
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      if (Array.isArray(response?.data?.data?.patientInvestigationList)) {
        setExistingInvestigations(response?.data?.data?.patientInvestigationList)
        console.log(response?.data?.data?.patientInvestigationList)
      }
      if (Array.isArray(response?.data?.data?.patientInvestigationReportScanList)) {
        setExistingInvestigationScans(response?.data?.data?.patientInvestigationReportScanList)
        console.log(response?.data?.data?.patientInvestigationReportScanList)
      }
    }
  }

  useEffect(() => {
    setupDataVisibleInvestigations()
  }, [])

  useEffect(() => {
    let uniqueId = uuidv4()
    uniqueId = uniqueId.replaceAll('-', '')
    setReportReferenceId('RPT-' + uniqueId)
  }, [])

  console.log(existingInvestigationScans)

  return (
    <AddOrViewInvsComponentContainer style={{ width: '100%', overflowX: 'scroll' }}>
      <div>
        <div style={{ marginBottom: '24px' }}>
          All new entries will be saved with following Report Reference Id and Report Date:
        </div>
        <div className="d-flex gap-2 align-items-center">
          <div>
            <b>Date:</b>
          </div>
          <div>
            <DatePicker value={reportDate} onChange={(newValue) => setReportDate(newValue)} />
          </div>
        </div>
        <div style={{ marginTop: '8px' }}>
          <b>Report Id:</b> {reportReferenceId}
        </div>
      </div>
      <div style={{ height: '1px', width: '100%', background: '#d3d3d3', margin: '24px 0' }}></div>
      <div>
        {initialDataStripsLoading && <Spinner animation="border" variant="dark" size="sm" />}
        {visibleInvestigationStrips?.map((visibleStripItem, visibleStripItemIndex) => {
          if (visibleStripItem?.investigationType === INVESTIGATION_TYPE_TEST) {
            return (
              <div
                style={{
                  borderTop: '1px solid #d3d3d3',
                  borderBottom: '1px solid #d3d3d3',
                  margin: '12px 0',
                }}
                key={visibleStripItemIndex}
              >
                <SingleInvestigationComponent
                  templateData={visibleStripItem}
                  existingInvestigations={existingInvestigations}
                  updateExistingDataValues={updateExistingDataValues}
                  reportDate={reportDate}
                  patientId={patientId}
                  clinicBrandId={clinicBrandId}
                  reportReferenceId={reportReferenceId}
                  mentorId={mentorId}
                />
              </div>
            )
          }
          if (visibleStripItem?.investigationType === INVESTIGATION_TYPE_PANEL) {
            return (
              <div
                style={{
                  borderTop: '1px solid #d3d3d3',
                  borderBottom: '1px solid #d3d3d3',
                  margin: '12px 0',
                }}
                key={visibleStripItemIndex}
              >
                <PanelInvestigationComponent
                  templateData={visibleStripItem}
                  existingInvestigations={existingInvestigations}
                  updateExistingDataValues={updateExistingDataValues}
                  reportDate={reportDate}
                  patientId={patientId}
                  clinicBrandId={clinicBrandId}
                  reportReferenceId={reportReferenceId}
                  mentorId={mentorId}
                />
              </div>
            )
          }
          return null
        })}
      </div>

      <PlannedReportScanUploadComponent
        patientId={patientId}
        mentorId={mentorId}
        clinicBrandId={clinicBrandId}
        reportDate={reportDate}
        reportReferenceId={reportReferenceId}
        updateExistingDataValues={updateExistingDataValues}
      />
      <PlannedReportViewComponent
        patientId={patientId}
        mentorId={mentorId}
        clinicBrandId={clinicBrandId}
        existingInvestigationScans={existingInvestigationScans}
      />
    </AddOrViewInvsComponentContainer>
  )
}

const SingleInvestigationComponent = ({
  templateData,
  existingInvestigations,
  updateExistingDataValues,
  reportDate,
  patientId,
  clinicBrandId,
  reportReferenceId,
  mentorId,
}) => {
  const [saveLoading, setSaveLoading] = useState(false)
  const [value, setValue] = useState('')
  const [unit, setUnit] = useState('')

  const historyOfThisInvestigation = existingInvestigations?.filter(
    (item) => item?.investigationId === templateData?.id,
  )
  const normalRange = (templateData?.referenceRange || [])?.find((item) => item?.normalRange)
  sortArrayMomentBased(historyOfThisInvestigation, 'reportDate', false)

  async function saveThis() {
    setSaveLoading(true)
    const data = {
      patientId: patientId,
      clinicBrandId: clinicBrandId,
      investigationId: templateData?.id,
      doctorMentorId: mentorId,
      reportDate: reportDate?.format('YYYY-MM-DD HH:mm:ss'),
      resultValue: value,
      resultUnit: unit,
      reportReferenceId: reportReferenceId,
      apiParam: 'patient-investigation',
    }
    const response = await postMPDataWithParams(
      '/api/secure/doctor/patient-investigation/save',
      data,
      {},
    )
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      updateExistingDataValues()
      setValue('')
    }
    setSaveLoading(false)
  }

  useEffect(() => {
    if (Array.isArray(templateData?.units) && templateData?.units?.length > 0) {
      setUnit(templateData?.units?.[0])
    }
  }, [])

  const options =
    templateData?.units?.map((item) => ({
      value: item,
      label: item,
    })) || []

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      setUnit(selectedOption?.value)
    }
  }

  return (
    <div style={{ display: 'flex', gap: '8px', padding: '8px', minHeight: '96px' }}>
      <div
        style={{
          width: '240px',
          textAlign: 'center',
          borderLeft: '1px solid #d3d3d3',
          borderRight: '1px solid #d3d3d3',
          flexShrink: 0,
        }}
      >
        <div>{templateData?.title}</div>
        <div style={{ fontSize: '12px' }}>
          {(normalRange?.min || normalRange?.max || normalRange?.unit) && <span>{'Normal: '}</span>}
          {normalRange?.min && normalRange?.max && (
            <span>
              {normalRange?.min}-{normalRange?.max}{' '}
            </span>
          )}
          {normalRange?.unit && <span>{normalRange?.unit}</span>}
        </div>
      </div>
      <div
        style={{
          borderTop: '1px solid #d3d3d3',
          borderBottom: '1px solid #d3d3d3',
          minWidth: '320px',
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          flexShrink: 0,
        }}
      >
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ border: '1px solid #d3d3d3', minHeight: '32px', padding: '4px', width: '80px' }}
        />
        {/* <select
          value={unit}
          onChange={(e) => setUnit(e.target.value)}
          style={{
            border: '1px solid #d3d3d3',
            minHeight: '32px',
            minWidth: '80px',
            padding: '4px',
          }}
        >
          {templateData?.units?.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select> */}
        <CreatableSelect
          value={options.find((option) => option.value === unit)}
          onChange={handleChange}
          options={options}
          placeholder="Select"
          styles={{
            container: (provided) => ({
              ...provided,
              // height: '28px',
              minWidth: '80px',
              borderRadius: 0,
            }),
            control: (provided) => ({
              ...provided,
              border: '1px solid #d3d3d3',
              borderRadius: '0 important',
            }),
            menu: (provided, state) => ({
              ...provided,
              borderRadius: 0,
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: 'none', // Hide the default arrow separator
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              display: 'none', // Hide the default dropdown arrow
            }),
          }}
        />
        <button
          style={{
            background: unit && value ? '#014cf7' : '#7f8389',
            color: '#fff',
            padding: '4px 16px',
            minHeight: '32px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: unit && value ? 'pointer' : 'default',
          }}
          disabled={!unit || !value}
          onClick={saveThis}
        >
          {saveLoading ? <Spinner animation="border" variant="light" size="sm" /> : <DoneIcon />}
        </button>
      </div>
      <div
        style={{
          borderTop: '1px solid #d3d3d3',
          borderBottom: '1px solid #d3d3d3',
          flex: '1 0 0',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        {historyOfThisInvestigation?.map((historyItem, historyItemIndex) => {
          return <HistoryItem key={historyItemIndex} data={historyItem} normalRange={normalRange} />
        })}
      </div>
    </div>
  )
}

const PanelInvestigationComponent = ({
  templateData,
  existingInvestigations,
  updateExistingDataValues,
  reportDate,
  patientId,
  clinicBrandId,
  reportReferenceId,
  mentorId,
}) => {
  const [visibleChildStrips, setVisibleChildStrips] = useState([])
  async function setChildrenSingleItems() {
    if (Array.isArray(templateData?.testIds) && templateData?.testIds?.length > 0) {
      const response = await getMPDataWithoutParams(
        `/api/secure/doctor/investigation/list?ids=${(templateData?.testIds || [])?.join(
          ',',
        )}&page=1&pageSize=10000`,
      )
      if (response?.status === 200 && Array.isArray(response?.data)) {
        setVisibleChildStrips(response?.data)
      }
    }
  }

  useEffect(() => {
    setChildrenSingleItems()
  }, [])

  return (
    <div style={{ padding: '16px 0' }}>
      <div>{templateData?.title}</div>
      <div style={{ padding: '24px', paddingLeft: '48px' }}>
        {visibleChildStrips?.map((visibleChildStrip, visibleChildStripIndex) => {
          return (
            <SingleInvestigationComponent
              key={visibleChildStripIndex}
              templateData={visibleChildStrip}
              existingInvestigations={existingInvestigations}
              updateExistingDataValues={updateExistingDataValues}
              reportDate={reportDate}
              patientId={patientId}
              clinicBrandId={clinicBrandId}
              reportReferenceId={reportReferenceId}
              mentorId={mentorId}
            />
          )
        })}
      </div>
    </div>
  )
}

//
//
//
//
//
//
//
//
//
//

const PlannedReportScanUploadComponent = ({
  patientId,
  mentorId,
  clinicBrandId,
  reportDate,
  reportReferenceId,
  updateExistingDataValues,
}) => {
  const [title, setTitle] = useState('')
  const [type, setType] = useState('image')
  const [selectedInvestigations, setSelectedInvestigations] = useState([])
  const [imageIds, setImageIds] = useState([])
  const [saveLoading, setSaveLoading] = useState(false)

  async function saveReportScan() {
    setSaveLoading(true)
    const data = {
      patientId: patientId,
      clinicBrandId: clinicBrandId,
      doctorMentorId: mentorId,
      investigationIds: selectedInvestigations?.map((item) => item?.id),
      storedFile: {
        type: type,
        fileIds: imageIds,
      },
      title: title,
      reportDate: reportDate?.format('YYYY-MM-DD HH:mm:ss'),
      reportReferenceId: reportReferenceId,
      apiParam: 'patient-investigation-report-scan',
    }
    const response = await postMPDataWithParams(
      '/api/secure/doctor/patient-investigation-report-scan/save',
      data,
      {},
    )
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      setTitle('')
      setType('image')
      setSelectedInvestigations([])
      setImageIds([])
      if (updateExistingDataValues) {
        updateExistingDataValues()
      }
    }
    setSaveLoading(false)
  }

  function deleteImageId(imageIdToRemove) {
    const updatedImageIds = imageIds.filter((imageId) => imageId !== imageIdToRemove)
    setImageIds(updatedImageIds)
  }

  return (
    <>
      <div style={{ height: '1px', width: '100%', background: '#d3d3d3', margin: '24px 0' }}></div>
      <div>
        <div style={{ marginBottom: '8px' }}>Add Scanned Reports:</div>
        <div style={{ marginBottom: '16px' }}>
          <CustomInput
            type="text"
            label={'Title'}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: '16px' }}>
          <CustomHTMLSelect
            label={'Scan Media Type'}
            onChange={(e) => setType(e.target.value)}
            options={[{ label: 'Image', id: 'image' }]}
            getOptionLabel={(data) => data?.label}
            getOptionValue={(data) => data?.id}
          />
        </div>
        <div style={{ marginBottom: '16px' }}>
          Investigations in this report
          <InvestigationsSelectMenu
            clinicBrandId={clinicBrandId}
            selectedInvestigations={selectedInvestigations}
            setSelectedInvestigations={setSelectedInvestigations}
          />
        </div>
        <div style={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
          {imageIds?.map((imageId) => {
            return (
              <div
                key={imageId}
                onClick={() => deleteImageId(imageId)}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <img
                  alt={'imageId'}
                  src={getImageLink(imageId)}
                  style={{
                    width: '64px',
                    height: '64px',
                    borderRadius: '8px',
                    border: '1px solid #cecece',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                  }}
                >
                  <DeleteOutlineSharpIcon
                    style={{
                      color: 'red',
                      fontSize: '24px',
                    }}
                  />
                </div>
              </div>
            )
          })}
          <PictureUploadButton onChange={(e) => setImageIds((prev) => [...prev, e.target.value])} />
        </div>

        <button
          style={{
            padding: '4px 8px',
            color: '#fff',
            borderRadius: '4px',
            marginTop: '32px',
            background:
              !saveLoading && title && type && Array.isArray(imageIds) && imageIds?.length > 0
                ? '#000'
                : 'gray',
          }}
          disabled={
            !(!saveLoading && title && type && Array.isArray(imageIds) && imageIds?.length > 0)
          }
          onClick={saveReportScan}
        >
          <span>{' Add '}</span>
          {saveLoading && <Spinner animation="border" variant={'light'} size="sm" />}
        </button>
      </div>
    </>
  )
}

const PlannedReportViewComponent = ({
  patientId,
  mentorId,
  clinicBrandId,
  reportReferenceId,
  existingInvestigationScans,
}) => {
  return (
    <>
      <div style={{ height: '1px', width: '100%', background: '#d3d3d3', margin: '24px 0' }}></div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ marginBottom: '8px' }}>View Scanned Reports:</div>
        {existingInvestigationScans?.map((scan, scanIndex) => {
          return <ViewScanStrip scanData={scan} key={scanIndex} />
        })}
      </div>
    </>
  )
}

const ViewScanStrip = ({ scanData }) => {
  const [chosenImg, setChosenImg] = useState((scanData?.storedFile?.fileIds || [])?.[0])
  const [arrayImages, setArrayImages] = useState(scanData?.storedFile?.fileIds || [])
  const [dialogOpen, setDialogOpen] = useState(false)
  console.log(dialogOpen)
  return (
    <div style={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
      <div>
        <div>Title: {scanData?.title}</div>
        <div>
          Report Date:{' '}
          {dayjs.utc(scanData?.reportDate, 'YYYY-MM-DD HH:mm:ss')?.format('DD-MMM-YYYY')}
        </div>
      </div>
      <>
        {(scanData?.storedFile?.fileIds || [])?.map((imgId) => {
          return (
            <div
              key={imgId}
              onClick={() => {
                setChosenImg(imgId)
                setDialogOpen(true)
              }}
              style={{
                cursor: 'pointer',
                width: '64px',
                maxWidth: '64px',
                height: '64px',
                maxHeight: '64px',
                borderRadius: '8px',
                border: '1px solid #cecece',
                overflow: 'hidden',
              }}
            >
              <img
                src={getImageLink(imgId)}
                alt="reportpage"
                style={{ height: '100%', width: '100%' }}
              />
            </div>
          )
        })}
      </>
      {dialogOpen && (
        <ImageDialog
          arrayImages={arrayImages}
          chosenImg={chosenImg}
          setChosenImg={setChosenImg}
          closeDialog={() => setDialogOpen(false)}
        />
      )}
    </div>
  )
}

const ImageDialog = ({ arrayImages, chosenImg, setChosenImg, closeDialog }) => {
  console.log(arrayImages)
  return (
    <Dialog open={true} onClose={closeDialog}>
      <div
        style={{
          minWidth: '500px',
          minHeight: '300px',
          position: 'relative',
          background: '#000',
          padding: '16px',
        }}
      >
        <div
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'right',
            width: '100%',
            marginBottom: '24px',
          }}
          onClick={closeDialog}
        >
          <CloseIcon style={{ fontSize: '24px', cursor: 'pointer', color: '#fff' }} />
        </div>
        <div style={{ position: 'relative' }}>
          {arrayImages?.length > 1 && arrayImages.indexOf(chosenImg) > 0 && (
            <div
              style={{
                position: 'absolute',
                left: 0,
                margin: 'auto',
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                setChosenImg(arrayImages[arrayImages.indexOf(chosenImg) - 1])
              }}
            >
              <ChevronLeftIcon style={{ fontSize: '32px', cursor: 'pointer' }} />
            </div>
          )}
          <img alt="img" style={{ width: '100%', height: '100%' }} src={getImageLink(chosenImg)} />
          {arrayImages?.length > 1 && arrayImages.indexOf(chosenImg) < arrayImages?.length - 1 && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                margin: 'auto',
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                setChosenImg(arrayImages[arrayImages.indexOf(chosenImg) + 1])
              }}
            >
              <ChevronRightIcon style={{ fontSize: '32px', cursor: 'pointer' }} />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}

const HistoryItem = ({ data, normalRange }) => {
  const [resultState, setResultState] = useState('unknown')

  useEffect(() => {
    if (data?.resultUnit === normalRange?.unit) {
      const minNumber = parseFloat(normalRange.min)
      const maxNumber = parseFloat(normalRange.max)
      const actualNumber = parseFloat(data.resultValue)

      if (!isNaN(minNumber) && !isNaN(maxNumber) && !isNaN(actualNumber)) {
        if (actualNumber >= minNumber && actualNumber <= maxNumber) {
          setResultState('normal')
        } else {
          setResultState('abnormal')
        }
      } else {
        setResultState('unknown')
      }
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{ minWidth: '60px', margin: 'auto 32px', display: 'flex', justifyContent: 'center' }}
      >
        <div
          style={{
            color: '#000',
            borderRadius: '4px 0 0 4px',
            padding: '4px 24px',
            borderLeft: '1px solid #d3d3d3',
            borderTop: '1px solid #d3d3d3',
            borderBottom: '1px solid #d3d3d3',
            background:
              resultState !== 'unknown'
                ? resultState === 'normal'
                  ? '#acf7ac'
                  : '#f6b0b1'
                : '#f0f0f0',
          }}
        >
          {data?.resultValue}
        </div>
        <div
          style={{
            background: '#014cf7',
            color: '#fff',
            borderRadius: '0 4px 4px 0',
            padding: '4px 8px',
            width: 'max-content',
          }}
        >
          {data?.resultUnit}
        </div>
      </div>
      {data?.reportDate && (
        <div style={{ fontSize: '12px' }}>
          {dayjs.utc(data?.reportDate, 'YYYY-MM-DD HH:mm:ss')?.format('DD-MMM-YYYY')}
        </div>
      )}
    </div>
  )
}
