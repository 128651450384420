import { PMPFooterContainer } from '../../PrescriptionMakingPageStyles'

export default function TwoColumnPrescriptionWithDisclaimerFooter({
  prescriptionData,
  noColor,
  hideQr = true,
}) {
  return (
    <PMPFooterContainer
      noColor={noColor}
      threeColFooter
      style={{
        display: 'flex',
        alignItems: 'flex-center',
        flexDirection: 'column',
        fontSize: '12px',
        padding: '5px 10px',
        textAlign: 'center',
      }}
    >
      <div>{prescriptionData?.footer?.disclaimerText}</div>
      <PMPFooterContainer style={{ borderTop: 'unset' }} noColor={noColor}>
        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '50%' }}
        >
          <div style={{ textAlign: 'right' }}>{prescriptionData?.footer?.headline}</div>
          <div style={{ textAlign: 'right' }}>{prescriptionData?.footer?.subtext}</div>
          <div style={{ textAlign: 'right' }}>{prescriptionData?.footer?.supportText}</div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '50%',
          }}
        >
          <div style={{ textAlign: 'left' }}>{prescriptionData?.footer?.headlineAlt}</div>
          <div style={{ textAlign: 'left' }}>{prescriptionData?.footer?.subtextAlt}</div>
          <div style={{ textAlign: 'left' }}>{prescriptionData?.footer?.supportTextAlt}</div>
        </div>
      </PMPFooterContainer>
    </PMPFooterContainer>
  )
}
