import { PMPColumnedFooter, PMPFooterContainer } from '../../PrescriptionMakingPageStyles'
import RelatedClinicDetails from './relatedClinicDetails/RelatedClinicDetails'

export default function ThreeColumnPrescriptionFooter({
  prescriptionData,
  noColor,
  hideQr = true,
}) {
  return (
    <>
      <PMPColumnedFooter id="three-col" key="three-col" columns={3} noColor={noColor}>
        <RelatedClinicDetails footerData={prescriptionData?.relatedClinicDetails?.[0]} />
        <RelatedClinicDetails footerData={prescriptionData?.relatedClinicDetails?.[1]} />
        <RelatedClinicDetails footerData={prescriptionData?.relatedClinicDetails?.[2]} />
      </PMPColumnedFooter>
      <PMPFooterContainer
        noColor={noColor}
        threeColFooter
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-center',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', padding: '2px 7px' }}>
          <div style={{ textAlign: 'center' }}>{prescriptionData?.footer?.headline}</div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '25px',
            alignItems: 'flex-center',
            padding: '2px 7px',
          }}
        >
          <div style={{ textAlign: 'right' }}>{prescriptionData?.footer?.subtext}</div>
          <div style={{ textAlign: 'left' }}>{prescriptionData?.footer?.supportText}</div>
        </div>
      </PMPFooterContainer>
    </>
  )
}
