import styled, { keyframes } from 'styled-components'
import journalIcon from 'src/assets/journal_icon.png'
import {
  ButtonBase,
  Drawer,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  styled as muiStyled,
} from '@mui/material'

const NavColors = {
  // to stay
  gemsInNavbarBgColor: '#FFFFFF',
  navbarPageSelectBgWithoutHoverColor: '#07070729',
  navbarPageSelectBgWithHoverColor: '#4c4c4c',
}

export const GlobalNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ${(props) => (props?.isMobile && props?.leavePaddingTop ? '40px' : '0px')};
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${(props) => (props?.dark ? props?.theme?.themeC1 : '#fff')};
  box-shadow: 7px -11px 13px 13px #c0c0c0;
  z-index: 999;
  font-family: Outfit;
`

export const GlobalNavParent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: min(1512px, 90%);
  height: 64px;
  margin: 0px 21px 0px 37px;
  @media only screen and (max-width: 720px) {
    margin: 0px 20.4px 0px 22.6px;
  }
`

export const GlobalNavLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 29px;
  @media (max-width: 720px) {
    gap: 9px;
  }
`

const gradientAnimation = keyframes`
    0%{
        background-position: 0% 50%;
    }
    50%{
        background-position: 100% 50%;
    }
    100%{
        background-position: 0% 50%;
    }
`

export const CoinsOnWhiteBg = styled.div`
  height: 35.63px;
  width: 85px;
  padding: 10px;
  border-radius: 0 30px 0 0;
  background-color: ${NavColors.gemsInNavbarBgColor};
  font-size: 21px;
  font-weight: 500;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  background: linear-gradient(-45deg, #f9d35c, #fff, #f9d35c);
  background-size: 400% 400%; /* You can adjust the size as needed */
  animation: ${gradientAnimation} 5s ease infinite;

  @media (max-width: 720px) {
    height: 25.63px;
    width: 70.06px;
    padding: 5px;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 400;
    gap: 4px;
  }
  @media (max-width: 520px) {
    display: none;
  }
`

export const GlobalNavCenter = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
`

export const GlobalNavRight = styled.div`
  display: flex;
  align-items: center;
  gap: 29px;
  justify-content: right;
  @media (max-width: 720px) {
    gap: 9px;
  }
`

export const BrandLogo = styled.img`
  width: 56.5px;
  height: 50.9px;
  border-radius: 6px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 720px) {
    width: 46.5px;
    height: 40.9px;
  }
`

export const TrialBox = styled.div`
  letter-spacing: 0em;
  text-align: center;
  color: ${(props) => (props?.darkText ? '#000' : '#ffffff')};
  cursor: pointer;
  border: ${(props) => (props?.darkText ? '1.5px solid #000' : '1.5px solid #a69bb8')};

  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  border-radius: 20px;
  padding: 3px;
  @media (min-width: 768px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 28px;
    padding: 7px;
  }

  @media (max-width: 520px) {
    display: none;
  }
`

export const GlobalNavCountryCode = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => (props?.darkText ? '#000' : 'white')};
  position: relative;
  top: 30px;
  left: 2px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 720px) {
    font-size: 12px;
    font-weight: 400;
  }
`

export const DropDownContainer = styled.div``

export const PageSelectStyles = {
  control: (defaultStyles, state) => ({
    ...defaultStyles,
    backgroundColor: NavColors.navbarPageSelectBgWithoutHoverColor,
    border: 'none',
    borderRadius: '19px',
    boxShadow: 'none',
    cursor: 'pointer',
    padding: '7px 10px',
    ':hover': {
      backgroundColor: NavColors.navbarPageSelectBgWithHoverColor, // Add the hover background color here
      transition: 'none',
    },
    '@media only screen and (max-width: 768px)': {
      ...defaultStyles['@media only screen and (max-width: 768px)'],
      borderRadius: '20px',
    },
  }),

  menuList: (defaultStyles, state) => {
    return {
      ...defaultStyles,
      '::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
      margin: 'auto',
      height: 'auto',
      maxHeight: 'none',
    }
  },

  option: (defaultStyles, state) => {
    return {
      ...defaultStyles,
      color: state.isSelected ? '#4C1487' : '#4C1487',
      backgroundColor: state.isSelected ? '#fff' : '#fff',
      fontSize: state.isSelected ? '17px' : '16px',
      fontWeight: state.isSelected ? '700' : '400',
      textAlign: 'left',
      '@media only screen and (max-width: 768px)': {
        ...defaultStyles['@media only screen and (max-width: 768px)'],
        fontSize: state.isSelected ? '14px' : '12px',
      },
      ':hover': {
        backgroundColor: '#c4c4c4',
      },
    }
  },

  menu: (defaultStyles, state) => ({
    ...defaultStyles,
    backgroundColor: '#fff',
    borderRadius: '11px',
    overflow: 'hidden',
    width: '200px',
    fontFamily: 'Outfit',
    marginTop: '0px',
    '@media only screen and (max-width: 768px)': {
      ...defaultStyles['@media only screen and (max-width: 768px)'],
      width: '120px',
    },
  }),

  placeholder: (defaultStyles, state) => ({
    ...defaultStyles,
    color: '#fff',
    fontWeight: '800',
    fontSize: '28px',
    textAlign: 'center',
    fontFamily: 'Outfit',
    '@media only screen and (max-width: 768px)': {
      ...defaultStyles['@media only screen and (max-width: 768px)'],
      fontWeight: '600',
      fontSize: '16px',
    },
  }),

  singleValue: (defaultStyles, state) => ({
    ...defaultStyles,
    color: '#fff',
    fontWeight: '800',
    fontSize: '25px',
    fontFamily: 'Outfit',
    '@media only screen and (max-width: 768px)': {
      ...defaultStyles['@media only screen and (max-width: 768px)'],
      fontWeight: '600',
      fontSize: '16px',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0, // Remove padding from the value container
  }),

  dropdownIndicator: (defaultStyles, state) => ({
    ...defaultStyles,
    padding: 0, // Remove padding from the arrow
  }),
}

export const PageSelectStylesDark = {
  control: (defaultStyles, state) => ({
    ...defaultStyles,
    backgroundColor: NavColors.navbarPageSelectBgWithoutHoverColor,
    border: 'none',
    borderRadius: '19px',
    boxShadow: 'none',
    cursor: 'pointer',
    padding: '7px 10px',
    ':hover': {
      backgroundColor: '#c2bfbf', // Add the hover background color here
      transition: 'none',
    },
    '@media only screen and (max-width: 768px)': {
      ...defaultStyles['@media only screen and (max-width: 768px)'],
      borderRadius: '20px',
    },
  }),

  menuList: (defaultStyles, state) => {
    return {
      ...defaultStyles,
      '::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
      margin: 'auto',
      height: 'auto',
      maxHeight: 'none',
    }
  },

  option: (defaultStyles, state) => {
    return {
      ...defaultStyles,
      color: state.isSelected ? '#4C1487' : '#4C1487',
      backgroundColor: state.isSelected ? '#fff' : '#fff',
      fontSize: state.isSelected ? '17px' : '16px',
      fontWeight: state.isSelected ? '700' : '400',
      textAlign: 'left',
      '@media only screen and (max-width: 768px)': {
        ...defaultStyles['@media only screen and (max-width: 768px)'],
        fontSize: state.isSelected ? '14px' : '12px',
      },
      ':hover': {
        backgroundColor: '#c4c4c4',
      },
    }
  },

  menu: (defaultStyles, state) => ({
    ...defaultStyles,
    backgroundColor: '#fff',
    borderRadius: '11px',
    overflow: 'hidden',
    width: '200px',
    fontFamily: 'Outfit',
    marginTop: '0px',
    '@media only screen and (max-width: 768px)': {
      ...defaultStyles['@media only screen and (max-width: 768px)'],
      width: '120px',
    },
  }),

  placeholder: (defaultStyles, state) => ({
    ...defaultStyles,
    color: '#000',
    fontWeight: '800',
    fontSize: '28px',
    textAlign: 'center',
    fontFamily: 'Outfit',
    '@media only screen and (max-width: 768px)': {
      ...defaultStyles['@media only screen and (max-width: 768px)'],
      fontWeight: '600',
      fontSize: '16px',
    },
  }),

  singleValue: (defaultStyles, state) => ({
    ...defaultStyles,
    color: '#000',
    fontWeight: '800',
    fontSize: '25px',
    fontFamily: 'Outfit',
    '@media only screen and (max-width: 768px)': {
      ...defaultStyles['@media only screen and (max-width: 768px)'],
      fontWeight: '600',
      fontSize: '16px',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0, // Remove padding from the value container
  }),

  dropdownIndicator: (defaultStyles, state) => ({
    ...defaultStyles,
    padding: 0, // Remove padding from the arrow
  }),
}

export const PageSelectStylesPlay = {
  control: (defaultStyles, state) => ({
    ...defaultStyles,
    backgroundColor: '#07070729',
    border: 'none',
    borderRadius: '27px',
    boxShadow: 'none',
    cursor: 'pointer',
  }),

  option: (defaultStyles, state) => {
    return {
      ...defaultStyles,
      color: state.isSelected ? '#4C1487' : '#4C1487',
      backgroundColor: state.isSelected ? '#fff' : '#fff',
      fontSize: state.isSelected ? '17px' : '16px',
      fontWeight: state.isSelected ? '700' : '400',
      textAlign: 'center',
      borderRadius: '11px',
      ':hover': {
        fontWeight: '600',
      },
    }
  },

  menu: (defaultStyles, state) => ({
    ...defaultStyles,
    backgroundColor: '#fff',
    borderRadius: '11px',
    width: '172px',
  }),

  placeholder: (defaultStyles, state) => ({
    ...defaultStyles,
    color: '#fff',
    fontWeight: '800',
    fontSize: '28px',
    textAlign: 'center',
  }),

  singleValue: (defaultStyles, state) => ({
    ...defaultStyles,
    color: '#fff',
    fontWeight: '800',
    fontSize: '28px',
    display: 'none',
  }),
}

export const JournalIcon = styled.div`
  width: 37.21px;
  height: 37.21px;
  border-radius: 7px;
  cursor: pointer;
  background-color: ${({ theme }) => theme?.themeC4};
  background-image: url(${journalIcon});
  background-size: 75% 75%;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 720px) {
    width: 27.21px;
    height: 27.21px;
  }
  @media (max-width: 520px) {
    display: none;
  }
`

export const SessionIcon = styled.div`
  width: 37.21px;
  height: 37.21px;
  border-radius: 7px;
  cursor: pointer;
  background-color: ${({ theme }) => theme?.themeC4};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  color: #ffffff;
  position: relative;
  @media (max-width: 720px) {
    width: 27.21px;
    height: 27.21px;
    font-size: 8px;
  }
  @media (max-width: 520px) {
    display: none;
  }
`

export const OneOnOneCountForIcon = styled.div`
  font-size: 10px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #fff;
  color: #000;
  border-radius: 50%;
  padding: 2px;
  top: -7px;
  right: -7px;
`

// session info strip here

export const SessionInfoStripContainer = styled.div`
  width: 100%;
  background: #fae3f3;
  display: flex;
  padding: 7px 15px;
  justify-content: space-between;
  @media (min-width: 768px) {
    padding: 5px 30px;
  }
`

export const JoinStripOpenHeading = styled.div`
  color: #2f1455;
  font-size: 18px;
  font-weight: 800;
  @media (min-width: 768px) {
    font-size: 30px;
  }
`

export const JoinStripOpenDescription = styled.div`
  color: #7f56ba;
  font-size: 12px;
  font-weight: 300;
  @media (min-width: 768px) {
    font-size: 16px;
  }
`

export const JoinStripMentorImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  @media (min-width: 768px) {
    width: 90px;
    height: 90px;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
`

export const JoinStripHeading = styled.div`
  color: #2f1455;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  cursor: default;
  @media (min-width: 768px) {
    font-size: 16px;
    font-weight: 800;
  }
`

export const SessionStartsInContainer = styled.div`
  background: #7551a8;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  border-radius: 13px;
  font-weight: 500;
  padding: 4px 12px;
  cursor: default;
  @media (min-width: 768px) {
    font-size: 14px;
    font-weight: 600;
    padding: 4px 20px;
    border-radius: 17px;
  }
`

export const MoreDetailsText = styled.div`
  color: #000;
  font-size: 9px;
  font-weight: 400;
  cursor: pointer;
  @media (min-width: 768px) {
    font-size: 12px;
  }
`

export const JoinButton = styled.button`
  background: linear-gradient(172deg, #feae36 0%, #fd7600 100%);
  color: #fff;
  font-size: 12px;
  border-radius: 13px;
  font-weight: 500;
  padding: 4px 12px;
  @media (min-width: 768px) {
    font-size: 14px;
    font-weight: 600;
    padding: 4px 20px;
    border-radius: 17px;
  }
`

// drawer to show list of professionals

export const BookedSlotsDrawer = styled(Drawer)`
  display: flex;
  justify-content: center;
  // backdrop-filter: blur(1px);
  background-color: rgba(106, 109, 210, 0.2);
  .MuiPaper-root {
    height: 60vh;
    width: 100%;
    left: auto;
    right: auto;
    background: #ffffff;
    background-size: 100% 100%;
    border-radius: 25px 25px 0px 0px;
    padding: 50px 0;
    ::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`

// slot notification

export const BookedSlotOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 23px;
  margin: 0 auto;
  gap: 16px;
  font-family: Outfit;
  width: 300px;
  ${(props) => props?.withMaxHeight && 'max-height: 400px;'};
  ${(props) => props?.withMaxHeight && 'overflow: auto;'};
  ${(props) => props?.withMaxHeight && 'overflow-x: hidden;'};
`

export const BookedSlotOverviewNewSlotButton = styled.button`
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  border-radius: 25px;
  background: linear-gradient(155deg, #6c89d2 0%, #9d5ce1 100%);
  padding: 14px 25px;
`

export const BookedSlotOverviewHeading = styled.div`
  color: #424242;
  font-size: ${(props) => (props?.secondary ? '13px' : '16px')};
  font-weight: ${(props) => (props?.secondary ? '500' : '700')};
  text-decoration: ${(props) => props?.secondary && 'underline'};
  line-height: 16px; /* 84.211% */
  cursor: ${(props) => (props?.secondary ? 'pointer' : 'default')};
`

export const BookedSlotItemContainer = styled.div`
  border-radius: 7px;
  background: #f1f5ff;
  padding: 12px 16px;
  color: #484848;
  font-size: 14px;
  font-weight: 600;
  cursor: ${(props) => (props?.pointer ? 'pointer' : 'default')};
`

export const ConsumerProfileButton = muiStyled(ButtonBase)(
  ({ theme }) => `
    border-radius: 7px;
    overflow:hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    
`,
)
