import LocationOnIcon from '@mui/icons-material/LocationOn'
import VideoChatIcon from '@mui/icons-material/VideoChat'
import { Avatar, Paper, Tooltip } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import { styled as muiStyled } from '@mui/material/styles'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import {
  allAvailableEventTypes,
  displayNamesOfEventTypes,
  STATUS_SESSION_CANCELLED,
} from 'src/consumer/constants'
import { openInNewTab } from 'src/consumer/helpers/utilFunctions'
import {
  getImageLink,
  isCommonResponseSuccessful,
  sleep,
} from 'src/mentor/helpers/utilityFunctions'
import { GenericDisplayFlex, GenericDisplayFlexColumn } from 'src/mentor/layout/Styles'
import { getAllRolesInBoolean } from 'src/mentor/layout/utils'
import {
  CopyEventLinkButton,
  ScheduleName,
} from '../MentorAvailabilityPage/MentorAvailabilityPageStyles'
import {
  BookedEventContainer,
  BookedEventTitle,
  EventsPageItemContainer,
  EventsPageMainItemsContainer,
  JoiningLink,
  StyledEventsRefreshIcon,
} from './MentorEventsPageStyles'
import dayjs from 'dayjs'
import { cancelBookedSession } from 'src/consumer/services/featureServices'

//////////////////////////////////////////////////////////////////////

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function MultipleSelectPanelMembers(props) {
  const { options, selected, handleMultiSelectPanelMembersChange } = props

  return (
    <div>
      <FormControl sx={{ m: 1, width: '100%' }}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selected}
          onChange={handleMultiSelectPanelMembersChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return <span>No panel members selected</span>
            } else {
              return `${selected?.length} members selected`
            }
          }}
          MenuProps={MenuProps}
        >
          {options?.map((option, index) => (
            <MenuItem key={index} value={option?.id}>
              <Checkbox checked={selected?.indexOf(option?.id) > -1} />
              <ListItemText primary={option?.name} />
              <Avatar src={getImageLink(option?.profilePicId)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export function MentorEventSummaryComponent({
  onClickEvent,
  item,
  handleCopyEventLink,
  handleOpenBookingPage,
}) {
  return (
    <EventsPageItemContainer onClick={onClickEvent} enabled={item?.enabled}>
      <ScheduleName main>{item?.title}</ScheduleName>
      <ScheduleName>
        {item?.meetingSrc === 'aumhum' ? <VideoChatIcon /> : <LocationOnIcon />}
        {item?.durationInMins} mins, {displayNamesOfEventTypes?.[item?.type]}
      </ScheduleName>
      <ScheduleName>
        Buffer: {item?.preBufferMins} mins before, {item?.postBufferMins} mins after
      </ScheduleName>
      <ScheduleName style={{ color: item?.enabled ? 'green' : 'red' }}>
        {item?.enabled ? 'Enabled' : 'Disabled'}
      </ScheduleName>
      <ScheduleName style={{ color: item?.publicEvent ? 'green' : 'red' }}>
        {item?.publicEvent ? 'Public' : 'Private'}
      </ScheduleName>
      <ScheduleName style={{ color: !item?.free ? 'green' : 'red' }}>
        {item?.free ? 'Free' : 'Paid'}
      </ScheduleName>
      {item?.uid && (
        <GenericDisplayFlex sb>
          <CopyEventLinkButton onClick={(e) => handleCopyEventLink(e, item)}>
            Copy Link
          </CopyEventLinkButton>
          <CopyEventLinkButton invert onClick={(e) => handleOpenBookingPage(e, item)}>
            View Booking Page
          </CopyEventLinkButton>
        </GenericDisplayFlex>
      )}
    </EventsPageItemContainer>
  )
}

export function MentorUpcomingPastEventsComponent({
  mentorProfileData,
  allEvents,
  refreshEvents,
  loadingSlots,
  onClickJoin = () => {},
  eventFindLogicOverride = (eventItem, item) => eventItem?.uid === item?.eventTypeId,
}) {
  const [bookedEventsSelectedView, setBookedEventsSelectedView] = useState('upcoming')
  const [currentTime, setCurrentTime] = useState(moment().format('YYYY-MM-DD HH:mm:ss'))
  const [cancelDialogData, setCancelDialogData] = useState(null)

  // const upcomingBookedEvents = allEvents?.filter((item) =>
  //   moment?.utc(item?.endTime)?.local()?.isSameOrAfter(moment?.(currentTime)),
  // )
  // const pastBookedEvents = allEvents
  //   ?.filter((item) => moment?.utc(item?.endTime)?.local()?.isBefore(moment?.(currentTime)))
  //   ?.reverse()

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format('YYYY-MM-DD HH:mm:ss'))
    }, 60000)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <GenericDisplayFlex sb style={{ flexWrap: 'wrap', padding: '20px', paddingBottom: '0' }}>
        <GenericDisplayFlex style={{ flexWrap: 'wrap', gap: '20px' }}>
          {/* <NewEventTypeButton
            selected={bookedEventsSelectedView === 'upcoming'}
            MRZERO
            onClick={() => setBookedEventsSelectedView('upcoming')}
          >
            Active/ Upcoming
          </NewEventTypeButton>
          <NewEventTypeButton
            selected={bookedEventsSelectedView === 'past'}
            onClick={() => setBookedEventsSelectedView('past')}
          >
            Past
          </NewEventTypeButton> */}
        </GenericDisplayFlex>
        {loadingSlots ? (
          <Spinner animation="border" variant="dark" size="sm" />
        ) : (
          <Tooltip title={<strong>Refresh Bookings</strong>}>
            <StyledEventsRefreshIcon onClick={refreshEvents} />
          </Tooltip>
        )}
      </GenericDisplayFlex>
      <EventsPageMainItemsContainer>
        {allEvents?.map((item, index) => {
          console.log(item)
          return (
            <BookedEventContainer key={index}>
              <div>
                <BookedEventTitle>
                  {moment?.utc(item?.startTime).local()?.format('dddd, D MMMM YYYY')}
                </BookedEventTitle>
                <div>
                  {moment?.utc(item?.startTime).local()?.format('h:mma')} -{' '}
                  {moment?.utc(item?.endTime).local()?.format('h:mma')} ({moment().format('Z')})
                </div>
                {allEvents?.find((eventItem) => eventFindLogicOverride(eventItem, item))?.title} (
                {displayNamesOfEventTypes?.[item?.eventType]})
              </div>
              <GenericDisplayFlexColumn style={{ alignItems: 'flex-end' }}>
                <div>Booked by: {item?.userName}</div>
                {item?.status === STATUS_SESSION_CANCELLED ? (
                  <div style={{ color: 'red' }}>Cancelled</div>
                ) : (
                  <>
                    <JoiningLink>No Notes</JoiningLink>
                    {item?.patientId &&
                      getAllRolesInBoolean().isDoctor &&
                      mentorProfileData?.allowedToPrescribe && (
                        <JoiningLink
                          onClick={() => {
                            openInNewTab(
                              window.location.origin +
                                `/mentor-home/prescription?eventId=${item?.id}&patientId=${item?.patientId}&clinicId=${item?.clinicId}`,
                            )
                          }}
                        >
                          Prescription
                        </JoiningLink>
                      )}
                    {item?.patientId && item?.eventType === allAvailableEventTypes.screening && (
                      <JoiningLink
                        onClick={() => {
                          openInNewTab(
                            window.location.origin +
                              `/mentor-home/screening-report?eventId=${item?.id}&patientId=${item?.patientId}&clinicId=${item?.clinicId}`,
                          )
                        }}
                      >
                        Screening Report
                      </JoiningLink>
                    )}
                    {item?.patientId && item?.eventType === allAvailableEventTypes.therapySession && (
                      <JoiningLink
                        onClick={() => {
                          openInNewTab(
                            window.location.origin +
                              `/mentor-home/therapy-report?eventId=${item?.id}&patientId=${item?.patientId}&clinicId=${item?.clinicId}`,
                          )
                        }}
                      >
                        Therapy Report
                      </JoiningLink>
                    )}
                    <JoiningLink
                      onClick={() => {
                        // navigate(`/l/${item?.slug}`)
                        onClickJoin(item)
                      }}
                    >
                      Start
                    </JoiningLink>
                    <JoiningLink
                      onClick={() => {
                        setCancelDialogData(item)
                      }}
                    >
                      Cancel
                    </JoiningLink>
                  </>
                )}
              </GenericDisplayFlexColumn>
            </BookedEventContainer>
          )
        })}

        {allEvents?.length === 0 && <BookedEventTitle>No bookings</BookedEventTitle>}
      </EventsPageMainItemsContainer>
      {cancelDialogData && (
        <ConfirmCancelSessionDialog
          open={cancelDialogData}
          closeFunc={() => setCancelDialogData(null)}
          sessionData={cancelDialogData}
          afterCancelSuccess={refreshEvents}
        />
      )}
    </>
  )
}

export const ConfirmCancelSessionDialog = ({
  open,
  closeFunc,
  sessionData,
  afterCancelSuccess,
}) => {
  const [cancelLoading, setCancelLoading] = useState(false)

  async function cancelSession() {
    setCancelLoading(true)
    const response = await cancelBookedSession(sessionData?.id)
    setCancelLoading(false)
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      if (afterCancelSuccess) {
        afterCancelSuccess()
      }
      if (closeFunc) {
        closeFunc()
      }
    }
  }

  return (
    <BootstrapDialog
      onClose={closeFunc}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperComponent={BootStrapDialogPaperComponent}
    >
      <div style={{ padding: '16px' }}>
        <div>
          <b>Are you sure you want to cancel this session?</b>
        </div>
        <div style={{ color: 'red' }}>
          <i>This action can not be undone.</i>
        </div>
        <div style={{ marginTop: '24px', marginBottom: '24px' }}>
          <div>Client Name: {sessionData?.userName}</div>
          <div>
            Time: {dayjs.utc(sessionData?.startTime).local().format('DD/MM/YY hh:mmA')} to{' '}
            {dayjs.utc(sessionData?.endTime).local().format('DD/MM/YY hh:mmA')}
          </div>
        </div>
        <button
          onClick={cancelSession}
          style={{ color: '#fff', background: 'red', padding: '4px 8px', borderRadius: '4px' }}
        >
          {'Cancel '}
          {cancelLoading && <Spinner animation="border" variant="light" size="sm" />}
        </button>
      </div>
    </BootstrapDialog>
  )
}

const BootstrapDialog = muiStyled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const BootStrapDialogPaperComponent = muiStyled(Paper)(
  ({ theme }) => `
    max-width: unset !important;
`,
)
