import styled, { keyframes } from 'styled-components'
import {
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  Paper,
  styled as muiStyled,
} from '@mui/material'
// LS: Live session

export const LSPageParentContainer = styled.div`
  height: 100vh;
  font-family: Outfit;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props?.sessionStarted ? '0' : '10px')};
  gap: ${(props) => (props?.sessionStarted ? '0' : '10px')};
`

export const LSPageHeaderTitle = styled.div`
  color: #373737;
  font-family: Outfit;
  font-size: ${(props) => (props?.main ? '20px' : '14px')};
  font-style: normal;
  font-weight: ${(props) => (props?.main ? '500' : '300')};
  line-height: normal;
  @media (min-width: 768px) {
    font-size: ${(props) => (props?.main ? '26px' : '18px')};
    font-style: normal;
    font-weight: ${(props) => (props?.main ? '500' : '300')};
  }
`

export const LSPageContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  gap: 20px;
`

export const LSVideoPartContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props?.sessionStarted ? '#202124' : '#effeff')};
  border-radius: ${(props) => (props?.sessionStarted ? '0' : '7px')};
  @media print {
    .hide-on-print {
      display: none;
    }
  }
`

export const LSVideoPartHeader = styled.div`
  height: 60px;
  width: 100%;
  background: #202124;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f2f2f2;
  @media print {
    display: none;
  }
`

export const LSVideoPartHeaderHeading = styled.div`
  width: 100%;
`

export const LSVideoPartHeaderButton = styled.button`
  padding: 2px 10px;
  font-size: 14px;
  border: 1px solid #f2f2f2;
  border-radius: 15px;
  @media (max-width: 768px) {
    display: none;
  }
`

export const LSVideoPartSingleText = styled.div`
  font-size: 16px;
  ${(props) => props?.underline && 'text-decoration: underline;'};
  font-weight: 600;
`

export const LSNotesPartContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const LSNotesContainerParent = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 7px;
  background: #78978e;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  min-height: 300px;
`

export const LSNotesContainerHeading = styled.div`
  width: 100%;
  padding: 10px 25px;
  background: rgb(236 225 255);
  color: #000;
  font-weight: 500;
`

export const LSNotesContainerTextArea = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: rgb(243 243 243);
  resize: none;
  color: #1f1f1f;
  padding: 20px 25px;
  font-size: 14px;
  font-weight: 400;

  :: -webkit-scrollbar {
    width: 5px;
  }

  :: -webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  :: -webkit-scrollbar-thumb {
    background: #3b4a46;
    border-radius: 5px;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  // Hide/EDIT scrollbar for IE, Edge and Firefox
`

export const LSButton = styled.button`
  background: ${(props) => (props?.secondary ? 'transparent' : '#3b4a46')};
  border: 1px solid #3b4a46;
  border-radius: 39px;
  padding: 0 16px;
  height: 50px;
  min-width: 150px;
  color: ${(props) => (props?.secondary ? '#3b4a46' : '#fff')};
  font-size: 14px;
  font-weight: ${(props) => (props?.secondary ? '500' : '600')};
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    padding: 0 18px;
    font-size: 18px;
  }
  &:disabled {
    background: #bbbdc3;
    border: #bbbdc3;
  }
`

export const NotesSectionButton = styled.div`
  background: ${(props) => (props?.primary ? '#3b4a46' : '#fff')};
  border: ${(props) => (props?.primary ? 'none' : '1px solid #3b4a46')};
  border-radius: 39px;
  padding: 0 15px;
  color: ${(props) => (!props?.primary ? '#3b4a46' : '#fff')};
  font-size: 14px;
  font-weight: 600;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 35px;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`

const blinkKeyframe = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const SessionRecording = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #fff;
  background: #000;
  border-radius: 15px;
  text-transform: uppercase;
  font-size: 14px;
  padding: 2px 10px;
`

export const RedCircle = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #f7392e;
  animation: ${blinkKeyframe} 1s infinite alternate; /* Apply the blinking animation */
`

export const NotesOverlayDrawer = styled(Drawer)`
  // backdrop-filter: blur(1px);
  background-color: rgba(106, 109, 210, 0.2);

  .MuiPaper-root {
    height: 100vh;
    width: 300px;
    background: #ffffff;
    border-radius: 10px;
    overflow: hidden;
  }
`

export const LSSidebarAndContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const LSOfflineSidebarContainer = styled.div`
  background: #202124;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #fff;
  width: 100px;
  align-items: center;
  padding: 50px 0;
  gap: 20px;
  @media print {
    display: none;
  }
`

export const LSMainContent = styled.div`
  display: flex;
  width: calc(100% - 100px);
  @media print {
    width: 100%;
  }
`

export const NextSessionContainer = styled.div`
  width: 300px;
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 10px;
  color: #000;
  font-weight: 600;
  margin-bottom: 5px;
  cursor: pointer;
`

function getActivityPlanTableRowBackground(theme, header, index) {
  if (header) {
    return '#6a70d6'
  }
  if (index % 2 !== 0) {
    return '#eaeaea'
  }
}

export const LSActivityPlanTableContainer = muiStyled('div')(
  ({ theme }) => `
  overflow-x: auto;
  border-radius: 7px;
`,
)

export const LSActivityPlanTableRows = muiStyled('div')(
  ({ theme, header, index }) => `
  min-width: 768px;
  font-family: Outfit !important;
  display: grid;
  grid-template-columns: 90px 2fr 3fr 1fr 3fr 2fr;
  background: ${getActivityPlanTableRowBackground(theme, header, index)};
  font-weight: ${header ? '600' : '400'};
  border-bottom: 1px solid #00000036;
  padding: 10px 0px;
  color: ${header ? 'white' : 'unset'};
`,
)

export const LSActivityPlanContainerParent = muiStyled('div')(
  ({ theme }) => `
  padding: 0px 20px;
`,
)

export const LSActivityPlanContainerHeading = muiStyled('div')(
  ({ theme }) => `
  font-size: 35px;
  font-weight: 600;
  @media(min-width: 768px) {
  }
`,
)

export const LSActivityPlanCells = muiStyled('div')(
  ({ theme, actionButton }) => `
      padding: 0px 10px;
      align-items: center;
      display: flex;
      overflow: hidden;
  ${
    actionButton
      ? `
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: sticky;
      right: 0px;
      background: inherit;
  `
      : ''
  }
`,
)

export const EllipsisText = muiStyled('span')(
  ({ theme }) => `
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;`,
)

export const LSActivityPlanItemsContainer = muiStyled('div')(
  ({ theme }) => `
`,
)

export const AssignButton = muiStyled(ButtonBase)(
  ({ theme }) => `
  font-family: Outfit!important;
  padding: 6px 10px;
  position: relative;
  background: #6a70d6;
  color: #fff;
  border-radius: 7px;
  @media(min-width: 576px) {
  padding: 6px 20px;
  }
`,
)

export const LSActivityPlanFilterContainer = muiStyled(Grid)(
  ({ theme }) => `
  font-family: Outfit !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  background: ${theme.palette.background.paper};
  border-radius: 10px;
`,
)

export const LSActivityPlanDescriptionTooltipText = muiStyled('span')(
  ({ theme }) => `
  font-family: Outfit!important;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
`,
)

export const LSPlanOfferDialog = muiStyled(Paper)(
  ({ theme }) => `
  background: ${theme.palette.background.paper};
  border-radius: 10px;
`,
)

export const LSPlanOfferCancelButton = muiStyled(ButtonBase)(
  ({ theme }) => `
  padding: 6px 10px;
  border-radius: 7px;
  font-family: Outfit!important;
`,
)

export const LSPlanOfferAcceptButton = muiStyled(ButtonBase)(
  ({ theme }) => `
  padding: 6px 10px;
  background: #6a70d6;
  border-radius: 7px;
  color: #fff;
  font-family: Outfit!important;
`,
)

export const LSPlanBeingSentModalTitle = muiStyled('div')(
  ({ theme }) => `
  font-family: Outfit !important;
  font-size: 16px;

`,
)
export const LSPlanBeingSentModalDescription = muiStyled('div')(
  ({ theme }) => `
  font-family: Outfit !important;
  font-size: 14px;

`,
)
export const LSPlanBeingSentModalTotalDays = muiStyled('div')(
  ({ theme }) => `
  font-family: Outfit !important;
  font-size: 14px;

`,
)

export const LSPlanBeingSentModalContent = muiStyled(DialogContent)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: start;
  gap: 10px;
`,
)

export const LSPlanBeingSentModalDialogTitle = muiStyled(DialogTitle)(
  ({ theme }) => `
  font-family: Outfit!important;
  font-size: 25px;
  font-weight: 600;
`,
)

export const LSGetPlanDialog = muiStyled(Dialog)(
  ({ theme }) => `
  z-index: 1200;
`,
)

export const StartingCircularProgress = muiStyled(CircularProgress)`
  color: #9F8CA6FF;
`

export const MicCameraToggleContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: 1px solid #787878;
  background: ${(props) => (props?.isActive ? 'transparent' : '#ea4335')};
  border-color: ${(props) => (props?.isActive ? '#787878' : '#ea4335')};
  color: ${(props) => (props?.isActive ? '#787878' : '#FFF')};
  transition: all 0.2s ease-in-out;
  font-size: 20px;
  position: relative;
  @media (min-width: 768px) {
    height: 60px;
    width: 60px;
  }
  &:hover {
    background: ${(props) => (props?.isActive ? '#e8e8e8' : '#ce3b2f')};
  }
`

export const AddtoCalendarStyledButton = muiStyled(ButtonBase)(
  ({ theme }) => `
  padding: 6px 10px;
  background: #FFF;
  border-radius: 7px;
  color: #000;
  font-family: Outfit !important;
  display: flex;
  gap: 3px;
  font-weight: 400 !important;
  background: #f1e9c9;
  `,
)

export const LSPlanShowDrawerPaperProps = {
  maxWidth: '1200px',
}

export const LSPageInteractionPart = styled.div`
  height: 100%;
  width: ${(props) => (props?.big ? '650px' : '200px')};
  flex-shrink: 0;
  overflow: scroll;
  padding: 10px;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const LSPageImmediateVideoCallContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  @media print {
    display: none;
  }
`

export const DrawerForOtherItemsOnMobile = styled(Drawer)`
  display: flex;
  justify-content: center;
  background-color: rgba(106, 109, 210, 0.2);
  .MuiPaper-root {
    height: 95vh;
    width: 95%;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    background: #ffffff;
    background-size: 100% 100%;
    border: 1px solid #ffffff;
    border-radius: 4px 4px 0px 0px;
    overflow: hidden;
    overflow-y: auto; /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }
  }
`