import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userCreditsData: null,
  userPurchasesData: null,
  profileData: null,
  setupData: null,
  assessmentGoBackState: null,
  agoraChatServerConnection: null,
  agoraChatId: null,
  agoraChatToken: null,
  patientInfoList: null
}

const personalDataSlice = createSlice({
  name: 'personalDataStore',
  initialState: initialState,
  reducers: {
    setUserCreditsData(state, action) {
      return { ...state, userCreditsData: action.payload }
    },
    setUserPurchasesData(state, action) {
      return { ...state, userPurchasesData: action.payload }
    },
    setProfileData(state, action) {
      return { ...state, profileData: action.payload }
    },
    setSetupData(state, action) {
      return { ...state, setupData: action.payload }
    },
    setAssessmentGoBackState(state, action) {
      return { ...state, assessmentGoBackState: action.payload }
    },
    setAgoraChatServerConnection(state, action) {
      return { ...state, agoraChatServerConnection: action.payload }
    },
    setAgoraChatId(state, action) {
      return { ...state, agoraChatId: action.payload }
    },
    setAgoraChatToken(state, action) {
      return { ...state, agoraChatToken: action.payload }
    },
    setPatientInfoList(state, action) {
      return { ...state, patientInfoList: action.payload }
    },
  },
})

export const {
  setUserCreditsData,
  setUserPurchasesData,
  setProfileData,
  setSetupData,
  setAssessmentGoBackState,
  setAgoraChatServerConnection,
  setAgoraChatId,
  setAgoraChatToken,
  setPatientInfoList
} = personalDataSlice.actions
export const personalDataReducer = personalDataSlice.reducer

function selectPersonalDataStore(state){
  return state.personalDataStore
}

export const selectUserCreditsData = (state) => {
  return selectPersonalDataStore(state).userCreditsData
}
export const selectUserPurchasesData = (state) => {
  return selectPersonalDataStore(state).userPurchasesData
}
export const selectProfileData = (state) => {
  return selectPersonalDataStore(state).profileData
}
export const selectSetupData = (state) => {
  return selectPersonalDataStore(state).setupData
}
export const selectAssessmentGoBackState = (state) => {
  return selectPersonalDataStore(state).assessmentGoBackState
}
export const selectAgoraChatServerConnection = (state) => {
  return selectPersonalDataStore(state).agoraChatServerConnection
}
export const selectAgoraChatId = (state) => {
  return selectPersonalDataStore(state).agoraChatId
}
export const selectAgoraChatToken = (state) => {
  return selectPersonalDataStore(state).agoraChatToken
}
export const selectPatientInfoList = (state) => {
  return selectPersonalDataStore(state).patientInfoList
}
