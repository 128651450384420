import { Link } from '@mui/material'
import styled from 'styled-components'

export const SquareMidCardsContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  gap: 30px;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /* For iOS devices */
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  padding: 20px;
  @media (min-width: 768px) {
    padding: 35px;
    gap: 60px;
  }
`

export const SquareMidImage = styled.div`
  width: 45%;
  background-image: url(${(props) => props.imgURL});
  background-repeat: no-repeat;
  background-size: cover;
`

export const SquareMidNumber = styled.div`
  position: absolute;
  font-style: normal;
  color: #ffffff;
  bottom: 10px;
  left: 10px;
  font-weight: 700;
  font-size: 18px;
  @media (min-width: 768px) {
    bottom: 15px;
    left: 15px;
    font-weight: 700;
    font-size: 21px;
  }
  @media (min-width: 1296px) {
    bottom: 15px;
    left: 15px;
    font-weight: 700;
    font-size: 24px;
  }
`

export const SquareMidCardBackground = styled.img`
  height: 100%;
  width: 100%;
  transition: transform 0.5s ease-in-out;
  object-fit: cover;
  transform: scale(1.01);
`

export const SquareMidCardDetails = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  color: #ffffff;

  transform: translateY(20px);
  transition: transform 0.5s ease-in-out;
  @media (min-width: 768px) {
    transform: translateY(25px);
  }
`

export const SquareMidAppExclusiveText = styled.div`
  background: rgba(0, 0, 0, 0.44);
  backdrop-filter: blur(8.5px);
  /* Note: backdrop-filter has minimal browser support */
  text-align: center;
  display: flex;
  align-items: center;
  gap: 3px;

  font-size: 8px;
  margin-top: 8px;
  padding: 2px 5px;
  border-radius: 15px;

  @media (min-width: 768px) {
    font-size: 12px;
    margin-top: 10px;
    padding: 3px 5px;
    border-radius: 20px;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    margin-top: 12px;
    padding: 5px 10px;
    border-radius: 25px;
  }
`

export const SquareMidCardCategory = styled.div`
  background: rgba(0, 0, 0, 0.38);
  backdrop-filter: blur(14px);
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  width: fit-content;
  cursor: default;

  padding: 2px;
  border-radius: 16px;
  font-size: 10px;
  line-height: 8px;

  @media (min-width: 768px) {
    padding: 4px;
    border-radius: 21px;
    font-size: 12px;
    line-height: 12px;
  }
  @media (min-width: 1024px) {
    padding: 7px;
    border-radius: 28px;
    font-size: 16px;
    line-height: 16px;
  }
`

export const SquareMidTitle = styled.div`
  color: #ffffff;
  font-style: normal;
  display: flex;
  justify-content: center;
  cursor: default;
  text-align: center;
  line-height: 1.1;

  width: 107px;
  font-weight: 600;
  font-size: 16px;
  @media (min-width: 768px) {
    width: 187px;
    font-weight: 600;
    font-size: 21px;
  }
`

export const SquareCardActionButton = styled.div`
  opacity: 0;
  transition: opacity 0.5s ease-out;
  display: flex;
  background: #ffffff;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  color: #000000;
  cursor: pointer;

  font-size: 8px;
  width: 75px;
  height: 20px;
  margin-top: 6px;

  @media (min-width: 768px) {
    font-size: 12px;
    width: 100px;
    height: 25px;
    margin-top: 9px;
  }
`

export const SquareMidCardContainer = styled.div`
  flex: 0 0 129px;
  height: 138px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  cursor: ${({ nopointer }) => (nopointer ? 'unset' : 'pointer')};
  &:hover {
    ${SquareMidCardBackground} {
      transform: scale(1.05);
    }
    ${SquareCardActionButton} {
      opacity: 1;
    }
    ${SquareMidCardDetails} {
      transform: translateY(-10px);
      @media (min-width: 768px) {
        transform: translateY(-15px);
      }
      @media (min-width: 1024px) {
        transform: translateY(-20px);
      }
    }
  }
  @media (min-width: 768px) {
    flex: 0 0 229px;
    height: 238px;
    border-radius: 8px;
  }
`

export const SquareMidCardContainerAsAnchor = styled(Link)`
  flex: 0 0 129px;
  height: 138px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: unset;
  cursor: ${({ nopointer }) => (nopointer ? 'unset' : 'pointer')};
  &:hover {
    ${SquareMidCardBackground} {
      transform: scale(1.05);
    }
    ${SquareCardActionButton} {
      opacity: 1;
    }
    ${SquareMidCardDetails} {
      transform: translateY(-10px);
      @media (min-width: 768px) {
        transform: translateY(-15px);
      }
      @media (min-width: 1024px) {
        transform: translateY(-20px);
      }
    }
  }
  @media (min-width: 768px) {
    flex: 0 0 229px;
    height: 238px;
    border-radius: 8px;
  }
`

export const SquareMidCardSecondaryText = styled.div`
  background: rgba(0, 0, 0, 0.38);
  backdrop-filter: blur(14px);
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  width: fit-content;
  cursor: default;

  padding: 2px;
  border-radius: 16px;
  font-size: 10px;
  line-height: 8px;

  @media (min-width: 768px) {
    padding: 4px;
    border-radius: 21px;
    font-size: 12px;
    line-height: 12px;
  }
`
