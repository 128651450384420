import { TextareaAutosize } from '@mui/base'
import styled from 'styled-components'

export const PrescriptionTabFinalParent = styled.div`
  @media print {
    .hide-on-print {
      display: none;
    }
    .make-all-borders-black-print {
      border: 1px solid #000;
    }
    @page {
      size: A4;
      orientation: portrait;

      /* Enable background graphics */
      -webkit-print-color-adjust: exact; /* For Chrome */
      print-color-adjust: exact; /* For other browsers */
    }
  }
`

//

export const PMPParent = styled.div`
  margin: 20px auto;
  border: ${(props) => (props?.noBorder ? '' : '1px solid #000')};
  width: 100%;
  max-width: 794px;
  // min-height: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  background: #fff;
`

export const PMPParentVarTwo = styled.div`
  margin: 20px auto;
  border: 1px solid black;
  width: 100%;
  max-width: 210mm;
  height: auto;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  background: #fff;
`

export const PMPHeadingBanner = styled.div`
  width: 100%;
  background: ${(props) => (props?.noColor ? '#FFF' : '#397c73')};
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => (props?.noColor ? '#000' : '#FFF')};
  border-bottom: 1px solid #000;
  letter-spacing: 1.5px;
  font-weight: 700;
  font-size: 18px;
  padding: 2px;
`

export const PMPButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px 0;
  width: 100%;
  position: sticky;
  top: 0px;
  background: #fafafa;
  z-index: 2;
  @media (min-width: 1080px) {
    flex-direction: row;
    gap: unset;
  }
`

export const PMPButton = styled.button`
  background: ${(props) => (props?.whitebg ? '#fff' : '#0098ff')};
  color: ${(props) => (props?.whitebg ? '#0098ff' : '#fff')};
  padding: 2px 10px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 30px;
  border: 1px solid #0098ff;
`

///

export const PMPHeaderLeftPart = styled.div`
  width: 50%;
  padding: 5px 20px;
`

export const PMPHeaderRightPart = styled.div`
  width: 50%;
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  align-items: end;
`

export const PMPHeaderDoctorText = styled.div`
  color: ${(props) => (props?.main ? '#000' : '#080808')};
  font-weight: ${(props) => (props?.main ? '600' : '400')};
  font-size: ${(props) => (props?.main ? '14px' : '12px')};
  ${(props) => props?.textRight && `text-align: right`};
`

export const PMPHeaderDetails = styled.div`
  background: ${(props) => (props?.noColor ? '#FFF' : '#c9eee8')};
  padding: 5px 20px;
  color: #000;
  border-bottom: 1px solid #000;
`

//

export const PMPFooterContainer = styled.div`
  background: ${({ noColor, accentColor = '#397c73' }) => (noColor ? '#FFF' : accentColor)};
  padding: 5px 20px;
  color: ${(props) => (props?.noColor ? '#000' : '#FFF')};
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-top: 1px solid #000;
`

export const PMPColumnedFooter = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props?.columns || 3}, 1fr)`};
  border-top: 1px solid #000;
  background: ${(props) => (props?.noColor ? '#FFF' : '#397c73')};
  color: ${(props) => (props?.noColor ? '#000' : '#FFF')};
`

//

export const PMPLockedSection = styled.div`
  width: 350px;
  flex-shrink: 0;
  margin-top: 20px;
  border: 1px solid #a2a2a2;
  border-radius: 10px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

// styles for prescription items selection components:

export const PMPSingleRowForDrugs = styled.div`
  width: 100%;
  display: grid;
  border: 1px solid #c6c6c6;
  grid-template-columns: ${(props) =>
    props?.reduceLastBox ? '35% 10% 15% 10% 10% 20%' : '2% 30% 10% 15% 10% 10% 20% 3%'};
`

export const PMPSingleRowBox = styled.div`
  background: ${(props) => (props?.whiteBG ? '#FFF' : '#cfcfcf')};
  color: #4e555b;
  display: flex;
  align-items: center;
  padding: 3px 0 3px 3px;
  ${(props) => props?.borderRight && 'border-right: 1px solid #b4b7ba'};
  justify-content: ${(props) => props?.centered && 'center'};
`

// general styles for prescription making page items

export const PMPSectionHeading = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
`

export const PMPSectionInputLabel = styled.div`
  min-width: 150px;
  max-width: 200px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 16px;
`

export const PMPSectionInputBox = styled.input`
  padding: 3px 3px;
  width: 100%;
  max-width: 700px;
  border: 1px solid #b4b7ba;
  border-radius: 5px;
  &:focus {
    border-color: green;
    outline: none;
  }
`

export const PMPSectionTextAreaBox = styled(TextareaAutosize)`
  padding: 5px 5px;
  width: 100%;
  min-height: 80px;
  max-width: 700px;
  border: 1px solid #b4b7ba;
  border-radius: 5px;
  &:focus {
    border-color: green;
    outline: none;
  }
`

export const SectionSecondaryHeadingAndContent = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  @media (min-width: 1080px) {
    flex-direction: row;
    gap: 20px;
  }
`
