import styled from 'styled-components'

//
//
//
//
//
//
//
// NEW

export const NewInvsComponentContainer = styled.div`
  width: 100%;
  padding: 16px;
`

//
//
//
//
//
//
//
//
//
//
// VIEW

export const ViewInvsComponentContainer = styled.div`
  width: 100%;
  padding: 16px;
`

//
//
//
//
//
//
//
// ADD OR VIEW

export const AddOrViewInvsComponentContainer = styled.div`
  width: 100%;
  padding: 16px;
`

//
//
//
//
//
//
//
//

export const InvestigationButtonVar = styled.button`
  background: ${(props) => (props?.valid ? '#000' : '#fff')};
  border-radius: 4px;
  border: ${(props) => (props?.valid ? '1px solid #000' : '1px solid #d3d3d3')};
  color: ${(props) => (props?.valid ? '#fff' : '#000')};
`
