import {
  IDB_DIAGNOSIS_STORENAME,
  IDB_DRUGS_STORENAME,
  IDB_HEALTH_COMPLAINTS_STORENAME,
} from 'src/consumer/constants'
import { createERMDatabase } from './indexedDb'

async function getDB() {
  const db = await createERMDatabase()
  return db
}

//
//
// IDB_HEALTH_COMPLAINTS_STORENAME:

export async function addOrUpdateHealthComplaintsInIdb(complaints) {
  const db = await getDB()
  const tx = db.transaction(IDB_HEALTH_COMPLAINTS_STORENAME, 'readwrite')
  const store = tx.objectStore(IDB_HEALTH_COMPLAINTS_STORENAME)

  for (const complaint of complaints) {
    if (complaint?.title) {
      await store.put({ ...complaint, lowerCaseIndexField: complaint?.title?.toLowerCase() }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllHealthComplaintsFromIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_HEALTH_COMPLAINTS_STORENAME, 'readonly')
  const store = tx.objectStore(IDB_HEALTH_COMPLAINTS_STORENAME)
  const allComplaints = await store.getAll()
  await tx.done
  return allComplaints
}

export async function searchHealthComplaintsInIdb(searchTerm) {
  return await searchItemsInIdb(searchTerm, IDB_HEALTH_COMPLAINTS_STORENAME, 'titleIDX')
}

//
//
// IDB_DIAGNOSIS_STORENAME:

export async function addOrUpdateDiagnosisInIdb(diagnosisList) {
  const db = await getDB()
  const tx = db.transaction(IDB_DIAGNOSIS_STORENAME, 'readwrite')
  const store = tx.objectStore(IDB_DIAGNOSIS_STORENAME)

  for (const diagnosis of diagnosisList) {
    if (diagnosis?.title) {
      await store.put({ ...diagnosis, lowerCaseIndexField: diagnosis?.title?.toLowerCase() }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllDiagnosisFromIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_DIAGNOSIS_STORENAME, 'readonly')
  const store = tx.objectStore(IDB_DIAGNOSIS_STORENAME)
  const allDiagnosis = await store.getAll()
  await tx.done
  return allDiagnosis
}

export async function searchDiagnosisInIdb(searchTerm) {
  return await searchItemsInIdb(searchTerm, IDB_DIAGNOSIS_STORENAME, 'titleIDX')
}

//
//
// IDB_DRUGS_STORENAME:

export async function addOrUpdateDrugsInIdb(drugsList) {
  const db = await getDB()
  const tx = db.transaction(IDB_DRUGS_STORENAME, 'readwrite')
  const store = tx.objectStore(IDB_DRUGS_STORENAME)

  for (const drug of drugsList) {
    if (drug?.name) {
      await store.put({ ...drug, lowerCaseIndexField: drug?.name?.toLowerCase() }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllDrugsFromIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_DRUGS_STORENAME, 'readonly')
  const store = tx.objectStore(IDB_DRUGS_STORENAME)
  const allDiagnosis = await store.getAll()
  await tx.done
  return allDiagnosis
}

export async function searchDrugsInIdb(searchTerm) {
  return await searchItemsInIdb(searchTerm, IDB_DRUGS_STORENAME, 'titleIDX')
}

//
//
//

export async function searchItemsInIdb(searchTerm, storeName, indexName) {
  if (!searchTerm || searchTerm.trim() === '') {
    return []
  }
  const db = await getDB()
  const tx = db.transaction(storeName, 'readonly')

  const index = tx.store.index(indexName)
  const searchLowercase = searchTerm.toLowerCase()
  const range = IDBKeyRange.bound(searchLowercase, searchLowercase + '\uffff')
  let cursor = await index.openCursor(range)

  const searchResults = []

  while (cursor) {
    searchResults.push(cursor.value)
    cursor = await cursor.continue()
  }

  return searchResults
}
