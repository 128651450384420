export default function RelatedClinicDetails({ footerData, lastItem, containerStyle = {} }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '5px',
        borderRight: !lastItem && '1px solid #000',
        ...containerStyle,
      }}
    >
      {/* <div>Clinic:-</div> */}
      {footerData?.clinicName && (
        <div style={{ whiteSpace: 'pre-wrap' }}>{footerData?.clinicName}</div>
      )}
      {footerData?.address && <div style={{ whiteSpace: 'pre-wrap' }}>{footerData?.address}</div>}
      {footerData?.timings && <div style={{ whiteSpace: 'pre-wrap' }}>{footerData?.timings}</div>}
      {footerData?.timingsAltText && (
        <div style={{ whiteSpace: 'pre-wrap' }}>{footerData?.timingsAltText}</div>
      )}
      {footerData?.bottomText && (
        <div style={{ whiteSpace: 'pre-wrap' }}>{footerData?.bottomText}</div>
      )}
      {footerData?.bottomText2 && (
        <div style={{ whiteSpace: 'pre-wrap' }}>{footerData?.bottomText2}</div>
      )}
    </div>
  )
}
